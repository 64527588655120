var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$store.state.deliveryCart.products &&
    _vm.$store.state.deliveryCart.products[0] &&
    _vm.isShopOpen &&
    _vm.isPickupUpPointOpen
  )?_c('v-footer',{attrs:{"app":"","id":"footer","elevation":18}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","sm":"7","md":"6","lg":"4"}},[_c('v-btn',{staticClass:"px-5",attrs:{"color":"info","block":"","depressed":"","x-large":"","rounded":""},on:{"click":function($event){return _vm.$router.push({
              path: '/menu/weborders/precart',
              query: _vm.$route.query,
            })}}},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"start","align":"center"}},[_c('v-badge',{attrs:{"value":true,"offset-x":"9","offset-y":"16","color":"white","content":8}},[_c('template',{slot:"badge"},[_c('span',{staticClass:"info--text"},[_vm._v(" "+_vm._s(_vm.getCartQuantity)+" ")])]),_c('v-icon',{attrs:{"color":"white","size":"1.8rem"}},[_vm._v(" mdi-basket-outline ")])],2)],1)],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('span',{staticClass:"text-h3",attrs:{"id":"cart-button-text"}},[_vm._v(" VER MI PEDIDO ")])]),_c('v-col',{staticClass:"px-0 text-end",attrs:{"cols":"3"}},[_c('span',{staticClass:"text-h3 text-end"},[_vm._v(" "+_vm._s(_vm.currencySymbol())+_vm._s(_vm.formatPrice( _vm.$store.getters[ "deliveryCart/totalPriceWithoutShippingCost" ], 2 ))+" ")])])],1)],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }