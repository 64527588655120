export default {
  methods: {
    cartQuantity(products) {
      let cartQty = 0;
      products.forEach(() => {
        cartQty++;
      });

      return cartQty;
    },
  },
};
