<template>
  <v-footer
    v-if="
      $store.state.deliveryCart.products &&
      $store.state.deliveryCart.products[0] &&
      isShopOpen &&
      isPickupUpPointOpen
    "
    app
    id="footer"
    :elevation="18"
  >
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="7" md="6" lg="4" class="px-0">
          <v-btn
            @click="
              $router.push({
                path: '/menu/weborders/precart',
                query: $route.query,
              })
            "
            color="info"
            class="px-5"
            block
            depressed
            x-large
            rounded
          >
            <v-container>
              <v-row justify="space-between" align="center">
                <v-col cols="3">
                  <v-row justify="start" align="center">
                    <v-badge
                      :value="true"
                      offset-x="9"
                      offset-y="16"
                      color="white"
                      :content="8"
                    >
                      <template slot="badge">
                        <span class="info--text">
                          {{ getCartQuantity }}
                        </span>
                      </template>
                      <v-icon color="white" size="1.8rem">
                        mdi-basket-outline
                      </v-icon>
                    </v-badge>
                  </v-row>
                </v-col>
                <v-col cols="6" class="px-1">
                  <span id="cart-button-text" class="text-h3">
                    VER MI PEDIDO
                  </span>
                </v-col>
                <v-col cols="3" class="px-0 text-end">
                  <span class="text-h3 text-end">
                    {{ currencySymbol()
                    }}{{
                      formatPrice(
                        $store.getters[
                          "deliveryCart/totalPriceWithoutShippingCost"
                        ],
                        2
                      )
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { formatPrice, currencySymbol } from "@/utils/price.js";
import cartQuantity from "../mixins/cart";
import shopUtils from "../mixins/shopUtils";

export default {
  name: "FooterGoToWebordersPrecart",
  mixins: [cartQuantity, shopUtils],
  provide: {
    heading: { align: "center" },
  },

  computed: {
    getCartQuantity() {
      return this.cartQuantity(this.$store.state.deliveryCart.products);
    },
  },

  methods: {
    formatPrice,
    currencySymbol,
  },
};
</script>
<style lang="sass" scoped>
#footer
  background-position: center bottom
  a
    text-decoration: none

#cart-button-text
  letter-spacing: 0.06rem !important
</style>
